import React from 'react';

import { CFormInput } from '@coreui/react';

import '../../scss/hrms-style.scss';

const HrFormInput = (props) => {
    return (
        props.title === false
            ? <>
                <CFormInput
                    className={"hrms-input-field-common border-0 " + props.className}
                    type={props.type}
                    id={props.id}
                    size={props.size}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    autoComplete={props.id}
                    style={{ minHeight: '4vh' }}
                />
            </>
            : <>
                <label htmlFor={props.id} className="hrms-account-label hrms-font-link d-flex align-items-center">
                    {
                        props.title_icon
                        && <>
                            <img src={props.title_icon} alt="" width='18vw' />
                            &nbsp;&nbsp;
                        </>
                    }
                    {props.title}
                </label>

                <CFormInput
                    className={"hrms-input-field-common border-0 " + props.className}
                    type={props.type}
                    id={props.id}
                    size={props.size}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    autoComplete={props.id}
                    style={{ minHeight: '4vh' }}
                />
            </>
    )
}

HrFormInput.defaultProps = {
    title: 'Form Input Component',
    placeholder: 'Form Input Component',
    size: 'md'
}

export default React.memo(HrFormInput)