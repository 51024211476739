import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";
import { authenticationService } from '../services';

const currentUser = authenticationService.currentUserValue;

export const rolesService = {
    getRoles,
    addRole,
    deleteRole,
    updateRole
};

async function getRoles() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/role-list`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
    });

    return response.data
}

async function addRole(data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/role`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function updateRole(id, data) {
    const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}/role/${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function deleteRole(uid) {
    const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API}/role/${uid}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response.data
}