import { holidayListConstraints } from '../constants';
import { holidayListService } from '../services';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

export const getHolidays = (data) => {
    return dispatch => {
        holidayListService.getHolidays(data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'all_holidays': response }));
                }
            )
    }

    function fetchSuccess(data) {
        return {
            type: holidayListConstraints.GET_HOLIDAY_LIST,
            payload: data
        }
    }
}

export const updateHoliday = (id, data) => {
    return dispatch => {
        holidayListService.updateHoliday(id, data)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'update_holidays': response }));
                    window.location = '/#/holidaylist';
                }
            )
    }

    function fetchSuccess(data) {
        return {
            type: holidayListConstraints.UPDATE_HOLIDAY,
            payload: data
        }
    }
}

export const deleteHoliday = (id) => {
    return dispatch => {
        holidayListService.deleteHoliday(id)
            .then(
                response => {
                    dispatch(fetchSuccess({ 'delete_holidays': response }));
                    window.location = '/#/holidaylist';
                }
            )
    }

    function fetchSuccess(data) {
        return {
            type: holidayListConstraints.UPDATE_HOLIDAY,
            payload: data
        }
    }
}

export const uploadHolidays = (data) => {
    return dispatch => {
        holidayListService.uploadHolidays(data)
            .then(
                response => {
                    dispatch(uploadSuccess({ 'upload_holidays': response }));
                    window.location = '/#/holidaylist';
                }
            )
    }

    function uploadSuccess(data) {
        return {
            type: holidayListConstraints.UPLOAD_HOLIDAY_LIST,
            payload: data
        }
    }
}