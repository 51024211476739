import { rolesConstrants } from '../constants';
import { rolesService } from '../services';
//import { history } from '../helpers';
import React, { Component } from 'react';

export const addRole = (data) => {

    return dispatch => {
        rolesService.addRole(data)
            .then(
                response => {
                    dispatch(addSuccess(response));
                    window.location = '/#/roles';
                }
            )
    }

    function addSuccess(report) {
        return {
            type: rolesConstrants.ADD_ROLE,
            payload: report
        }
    }
}

export const updateRole = (id, data) => {
    return dispatch => {
        rolesService.updateRole(id, data)
            .then(
                response => {
                    dispatch(updateSuccess(response));
                    window.location = '/#/roles';
                }
            )
    }

    function updateSuccess(report) {
        return {
            type: rolesConstrants.UPDATE_ROLE,
            payload: report
        }
    }
}

export const deleteRole = (id) => {
    return dispatch => {
        rolesService.deleteRole(id)
            .then(
                response => {
                    dispatch(deleteSuccess(response));
                    window.location = '/#/roles';
                }
            )
    }

    function deleteSuccess(report) {
        return {
            type: rolesConstrants.DELETE_ROLE,
            payload: report
        }
    }
}

export const getRoles = () => {
    return dispatch => {
        rolesService.getRoles()
            .then(
                response => {
                    dispatch(retrieveSuccess(response));
                }
            )
    }

    function retrieveSuccess(report) {
        return {
            type: rolesConstrants.RETRIEVE_ROLES,
            payload: report
        }
    }
}
