import axios from 'axios';

import { authenticationService } from "../services"

const currentUser = authenticationService.currentUserValue;

export const employeeService = {
    getAllEmployee,
    updateEmployee,
    getAllEmployeeNames,
    employeeRatio,
    employeeNewJoinings
};

async function getAllEmployee(token) {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_GET_ALL_EMPLOYEE}`,
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function getAllEmployeeNames() {
    try {
        const response = await axios({
            method: "get",
            url: `http://localhost/backend/public/api/employee-name-list`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function updateEmployee(id, token, data) {
    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_UPDATE_EMPLOYEE}${id}`,
            headers: { Authorization: `Bearer ${token}` },
            data: data
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function employeeRatio(token) {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_EMPLOYEE_RATIO}`,
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function employeeNewJoinings(token) {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_EMPLOYEE_NEW_JOININGS}`,
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function employeeWorkAnniversary(token) {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_EMPLOYEE_WORK_ANNIVERSARY}`,
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}

async function employeeBirthday(token) {
    try {
        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_EMPLOYEE_WORK_ANNIVERSARY}`,
            headers: { Authorization: `Bearer ${token}` }
        });

        return response.data
    } catch (err) {
        if (err.response.data === "Unauthorized.") {
            authenticationService.logout()
        } else {
            console.log(err)
        }
    }
}