import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";
import { authenticationService } from '../services';

export const jobsService = {
    getJobs,
    getSingleJob,
    addJobs,
    updateJob,
    deleteJob,
    latestJobOpenings
};

const currentUser = authenticationService.currentUserValue;

async function getJobs() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_JOB_LIST}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
    });

    return response.data
}

async function getSingleJob(id) {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_GET_JOB}${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
    });

    return response.data
}

async function addJobs(data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_ADD_JOB}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function updateJob(id, data) {
    const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_UPDATE_JOB}${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` },
        data: data
    });

    return response.data
}

async function deleteJob(id) {
    const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_DELETE_JOB}${id}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response.data
}

async function latestJobOpenings() {
    const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_JOB_OPENINGS}`,
        headers: { Authorization: `Bearer ${currentUser.token}` }
    });

    return response.data
}