import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { API_URL_ROOT } from "../actions/action.constant";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const manageUserService = {
    getUser,
    addUser,
    updateUser,
    deleteUser,
    fetchUsers
};

async function fetchUsers(token, page, perPage) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/users?start=${page}&length=${perPage}`,
        headers: { Authorization: `Bearer ${token}` },
    });

    return response.data
}

async function getUser(id, token) {
    try {

        const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API}/users/${id}`,
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.data
    } catch (err) {
        return err.response.data
    }
}

async function updateUser(id, token, data) {
    const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API}/user/${id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: data
    });

    return response
}

async function deleteUser(id, token) {
    const response = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API}/user/${id}`,
        headers: { Authorization: `Bearer ${token}` }
    });

    return response.data
}

async function addUser(token, data) {
    const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/register`,
        headers: { Authorization: `Bearer ${token}` },
        data: data
    });

    return response.data
}
